import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { tokens } from '../../theme';
// import { mockDataTeam } from '../../data/mockData';
// import  AdminPanelSettingsOutlinedIcon  from "@mui/icons-material/AdminPanelSettingsOutlined";
// import  LockOpenOutlinedIcon  from "@mui/icons-material/LockOpenOutlined";
// import  SecurityOutlinedIcon  from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import React, { useState, useEffect, useCallback } from 'react';
import { fetchData, getData } from '../../services/data';


const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const columns = [
    {
      field: "AmazonOrderId",
      flex: 1,
      headerName: "ORDER ID"
    },
    {
      field: 'OrderTotalAmount',
      headerName: 'Amount',
      flex: 1,
      align: "left",
      cellClassName: 'name-column-cell',
    },
    {
      field: 'OrderStatus',
      headerName: 'STATUS',
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: 'PaymentMethod',
      headerName: 'Payment Method',
      flex: 1,
    },
    {
      field: 'PurchaseDate',
      headerName: 'Purchase Date',
      flex: 1,
    },
    // {  
    //     field: 'access',
    //     headerName: 'Access Level',
    //     flex: 1,
    //     renderCell: ({ row: {access}}) => {
    //         return (
    //             <Box
    //             width="60%"
    //             m="5px auto"
    //             p="5px"
    //             display='flex'
    //             justifyContent="center"
    //             backgroundColor={
    //                 access === "admin"
    //                 ? colors.greenAccent[600]
    //                 : colors.greenAccent[700]
    //             }
    //             borderRadius="4px"
    //             >
    //                 { access === "admin" && <AdminPanelSettingsOutlinedIcon/>}
    //                 { access === "manager" && <SecurityOutlinedIcon/>}
    //                 { access === "user" && <LockOpenOutlinedIcon/>}
    //                 <Typography color={colors.grey[100]} sx={{ ml: "5px"}}>
    //                     {access}
    //                 </Typography>
    //             </Box>
    //         )
    //     }
    // },
  ];

  const [data, setData] = useState([]);
  const fetchInitialData = useCallback(async () => {
    await fetchData();
    console.log(Date.now);

    setData(getData());
  }, [fetchData]);

  useEffect(() => {

    fetchInitialData();
    const intervalId = setInterval(() => {
      fetchInitialData();
    }, 60000); // 60000 ms = 1 minute

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);

  }, [fetchInitialData]);

  // Add an id property to each row
  const modifiedData = data.map((item, index) => ({ ...item, id: index }));

  return (
    <Box m="20px">
      <Header title="ORDERS" subtitle="Managing the Orders" />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
            width: "100%"
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],

          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },

        }}
      >
        <DataGrid checkboxSelection rows={modifiedData} columns={columns} />
      </Box>
    </Box>
  );

}

export default Team