import { ColorModeContext, useMode } from "./theme";
import { useState } from "react";
import { Box, CssBaseline, ThemeProvider } from "@mui/material";
import Topbar from "./scenes/global/Topbar";
import Sidebarr from "./scenes/global/Sidebar";
import Dashboard from "./scenes/dashboard";
import Team from "./scenes/team";
import Contacts from "./scenes/contacts";
import QuickFilteringGrid from "./scenes/invoice"
import Faq from "./scenes/faq";
import Bar from "./scenes/bar";
import './App.css';

// import Invoices from "./scenes/invoices";
import { Routes, Route } from "react-router-dom";



function App() {
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [theme, colorMode] = useMode();

  const handleSidebarCollapseChange = (collapsed) => {
    setIsSidebarCollapsed(collapsed);
  };
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          <Box sx={{
          position:"fixed",
          overflowY:"scroll",
          height:"100vh",
          zIndex:"1000"
        }}
        className="hide-scrollbar"
          >
            <Sidebarr  onCollapseChange={handleSidebarCollapseChange} />
          </Box>
          
          <main className="content">
            <Box sx={{
              marginLeft: "80px", // Adjust based on your sidebar's width  isSidebarCollapsed ? "80px" : "225px"
              transition: "margin-left 0.3s",
            }}>
            <Topbar/>
            <Routes>
              <Route path="/" element={<Dashboard />} />
               <Route path="/team" element={<Team />} /> 
               <Route path="/contacts" element={<Contacts />} />
               <Route path="/invoices" element={<QuickFilteringGrid />} />

               <Route path="/faq" element={<Faq />} />
               <Route path="/bar" element={<Bar />} />


              {/*<Route path="/invoices" element={<Invoices />} />
               */}
            </Routes>
            </Box>
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
