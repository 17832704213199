import { Box, Typography, useTheme, Divider} from '@mui/material';
import { tokens } from '../theme';

const StatBox = ({title, subtitle , icon , increase }) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box width="100%" m="0 15px" >
            <Box display="flex" justifyContent="center">
                <Box>
                <Typography
                    variant="h2"
                    fontWeight="bold"
                    sx={{color: colors.greenAccent[500] }}
                    >
                        SALES
                    </Typography>
                    
                    <Typography
                    variant="h6"
                    fontWeight="bold"
                    sx={{color: colors.grey[100], textAlign:"center"}}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
            <Divider sx={{ borderColor: colors.grey[100], my: 1 }} />
                <Box display="flex" justifyContent="space-between" sx={{marginTop:"1re,", padding:"1rem"}}>
                <Typography
                    variant="h5"
                    sx={{color: colors.greenAccent[500] }}
                    >
                        {subtitle}
                        <br />
                        <span>sales</span>
                 </Typography>
                 <Typography
                    variant="h5"
                    fontStyle="italic"
                    sx={{color: colors.greenAccent[600] }}
                    >
                        <span>₹</span> {increase}
                    </Typography>
                </Box>
                <Box>
                    <Box sx={{border:`1px solid ${colors.primary[100]}`}}>
                        <Typography
                        sx={{textAlign:"center", padding:"4px"}}>Top Five Sellers</Typography>
                    </Box>
                    <Box display="flex" justifyContent="center" gap="7px" padding=".5rem">
                        <Typography variant="h5">7</Typography>
                        <Typography variant="h5">&times;</Typography>
                        <Typography variant="h5">icon</Typography>
                        <Typography variant="h5"> = </Typography>
                        <Typography variant="h5" sx={{color: colors.greenAccent[500] }}>$576</Typography>
                    </Box>
                    <Divider sx={{ borderColor: colors.grey[600], my: 1 }} />

                    <Box display="flex" justifyContent="center" gap="7px" padding=".5rem">
                        <Typography variant="h5">10</Typography>
                        <Typography variant="h5">&times;</Typography>
                        <Typography variant="h5">icon</Typography>
                        <Typography variant="h5"> = </Typography>
                        <Typography variant="h5" sx={{color: colors.greenAccent[500] }}>$576</Typography>
                    </Box>
                    <Divider sx={{ borderColor: colors.grey[600], my: 1 }} />

                    <Box display="flex" justifyContent="center" gap="7px" padding=".5rem">
                        <Typography variant="h5">456</Typography>
                        <Typography variant="h5">&times;</Typography>
                        <Typography variant="h5">icon</Typography>
                        <Typography variant="h5"> = </Typography>
                        <Typography variant="h5" sx={{color: colors.greenAccent[500] }}>$576</Typography>
                    </Box>
                    <Divider sx={{ borderColor: colors.grey[600], my: 1 }} />

                    
                </Box>
        </Box>
    )
}

export default StatBox;