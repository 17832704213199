import { Box, Button, IconButton, Typography, useTheme, useMediaQuery } from "@mui/material";
import React, { useState, useEffect, useCallback } from 'react';
import { tokens } from "../../theme";
import StatBox from "../../components/StatBox";
import Header from '../../components/Header';
import LineChart from "../../components/LineChart";
import { mockTransactions } from "../../data/mockData";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import EmailIcon from "@mui/icons-material/Email";
import { fetchData, getData } from '../../services/data';
import '../../App.css';

const Dashboard = () => {
    const [orders, setOrders] = useState([]);
    const [todayOrders, setTodayOrders] = useState([]);
    const [yesterdayOrders, setYesterdayOrders] = useState([]);
    const [lastThirtyDaysOrders, setLastThirtyDaysOrders] = useState([]);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [todaysale, settodaysale] = useState(0);
    const [yesterdaysale, setyesterdaysale] = useState(0);
    const [last30daysale, setlast30daysale] = useState(0);




    const fetchOrders = useCallback(async () => {
        try {

            await fetchData();
            setOrders(getData());
            const fetch = getData();

            const now = new Date();
            const todayStart = new Date(Date.UTC(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate()));
            const todayEnd = new Date(todayStart);
            todayEnd.setUTCHours(23, 59, 59, 999);
            console.log("todaystart", todayStart, " toend", todayEnd);

            const yesterdayStart = new Date(todayStart);
            yesterdayStart.setDate(yesterdayStart.getDate() - 1);
            const yesterdayEnd = new Date(todayStart);
            todayEnd.setUTCHours(23, 59, 59, 999);

            const filteredTodayOrders = fetch.filter(order => {
                const purchaseDate = new Date(order.PurchaseDate);
                return purchaseDate >= todayStart && purchaseDate <= todayEnd;
            });
            setTodayOrders(filteredTodayOrders);
            const Todaysale = filteredTodayOrders.reduce((total, order) => {
                const amount = parseFloat(order.OrderTotalAmount);
                return total + (isNaN(amount) ? 0 : amount);
              }, 0);
              settodaysale(Todaysale)

            const filteredYesterdayOrders = fetch.filter(order => {
                const purchaseDate = new Date(order.PurchaseDate);
                return purchaseDate >= yesterdayStart && purchaseDate < yesterdayEnd;
            });
            setYesterdayOrders(filteredYesterdayOrders);
            const ys = filteredYesterdayOrders.reduce((total, order) => {
                const amount = parseFloat(order.OrderTotalAmount);
                return total + (isNaN(amount) ? 0 : amount);
              }, 0);
              setyesterdaysale(ys)

            const thirtyDaysAgo = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 30, 0, 0, 0);

            const filteredLastThirtyDaysOrders = fetch.filter(order => {
                const purchaseDate = new Date(order.PurchaseDate);
                return purchaseDate >= thirtyDaysAgo && purchaseDate <= now;
            });
            setLastThirtyDaysOrders(filteredLastThirtyDaysOrders);
            const lts = filteredLastThirtyDaysOrders.reduce((total, order) => {
                const amount = parseFloat(order.OrderTotalAmount);
                return total + (isNaN(amount) ? 0 : amount);
              }, 0);
              setlast30daysale(lts)

        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    },[fetchData]);




    useEffect(() => {
        fetchOrders();

        const intervalId = setInterval(() => {
            fetchOrders();
            // console.log("team", data.lenght);
        }, 60000); // 60000 ms = 1 minute

        // Clean up the interval when the component is unmounted
        return () => clearInterval(intervalId);
    }, [fetchOrders]);



    return (
        <Box m="20px">
            <Box display="flex" justifyContent="space-between" alignItems="center" flexDirection={isMobile ? "column" : "row"}>
                <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />

                <Box mt={isMobile ? 2 : 0}>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <DownloadOutlinedIcon sx={{ mr: "10px" }} />
                        Download Reports
                    </Button>
                </Box>
            </Box>

            <Box
                display="grid"
                gridTemplateColumns={isMobile ? "repeat(1, 1fr)" : "repeat(12, 1fr)"}
                gridAutoRows="183px"
                gap="20px"
            // className="container"
            >
                <Box
                    gridColumn={isMobile ? "span 1" : "span 4"}
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ padding: "20px", height: "fit-content" }}
                >
                    <StatBox
                        title="Today"
                        subtitle={todayOrders.length}
                        increase={todaysale}
                        icon={
                            <EmailIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />
                        }
                    />
                </Box>

                <Box
                    gridColumn={isMobile ? "span 1" : "span 4"}
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ padding: "20px", height: "fit-content" }}
                >
                    <StatBox
                        title="Yesterday"
                        subtitle={yesterdayOrders.length}
                        increase={yesterdaysale}
                        icon={
                            <EmailIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />
                        }
                    />
                </Box>

                <Box
                    gridColumn={isMobile ? "span 1" : "span 4"}
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ padding: "20px", height: "fit-content" }}
                >
                    <StatBox
                        title="Last 30 Days"
                        subtitle={lastThirtyDaysOrders.length}
                        increase={last30daysale.toFixed(2)}
                        icon={
                            <EmailIcon sx={{ color: colors.greenAccent[600], fontSize: "26px" }} />
                        }
                    />
                </Box>

                <Box
                    gridColumn={isMobile ? "span 1" : "span 8"}
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                >
                    <Box
                        mt="25px"
                        p="0 30px"
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        flexDirection={isMobile ? "column" : "row"}
                    >
                        <Box>
                            <Typography
                                variant="h5"
                                fontWeight="600"
                                color={colors.grey[100]}
                            >
                                Revenue Generated
                            </Typography>
                            <Typography
                                variant="h3"
                                fontWeight="bold"
                                color={colors.greenAccent[500]}
                            >
                                $59,342.32
                            </Typography>
                        </Box>
                        <Box mt={isMobile ? 2 : 0}>
                            <IconButton>
                                <DownloadOutlinedIcon
                                    sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                                />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box height="300px" m="-20px 0 0 0">
                        <LineChart isDashboard={true} />
                    </Box>
                </Box>

                <Box
                    gridColumn={isMobile ? "span 1" : "span 4"}
                    gridRow="span 2"
                    backgroundColor={colors.primary[400]}
                    overflow="auto"
                    className="hide-scrollbar"
                >
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        borderBottom={`4px solid ${colors.primary[500]}`}
                        colors={colors.grey[100]}
                        p="15px"
                    >
                        <Typography color={colors.grey[100]} variant="h5" fontWeight="600">
                            Recent Transactions
                        </Typography>
                    </Box>
                    {mockTransactions.map((transaction, i) => (
                        <Box
                            key={`${transaction.txId}-${i}`}
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            borderBottom={`4px solid ${colors.primary[500]}`}
                            p="15px"
                        >
                            <Box>
                                <Typography
                                    color={colors.grey[100]}
                                    variant="h5"
                                    fontWeight="600"
                                >
                                    {transaction.txId}
                                </Typography>
                                <Typography color={colors.grey[100]}>
                                    {transaction.user}
                                </Typography>
                            </Box>
                            <Box color={colors.grey[100]}>{transaction.date}</Box>
                            <Box
                                backgroundColor={colors.greenAccent[500]}
                                p="5px 10px"
                                borderRadius="4px"
                            >
                                ${transaction.cost}
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
