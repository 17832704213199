import { Box } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useTheme } from "@mui/material";
import { useState, useEffect, useCallback } from 'react';
import { fetchitemsData, getitems } from '../../services/data';

const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const column = [
    { field: "id", headerName: "ID", flex: 0.3 },
    {
      field: "image_url",
      headerName: "Icon",
      flex: 0.5,
      renderCell: ({ row: { image_url } }) => {
        return (
          <img
            src={image_url}  // Assuming `access` contains the image URL
            alt="Product Icon"
            style={{ width: 50, height: 50, objectFit: "contain" }}  // Adjust the size as needed
          />
        );
      },
    },
    { field: "asin", headerName: "ASIN" },
    {
      field: "seller_sku",
      headerName: "Seller SKU",
      flex: 1,
    },
    {
      field: "item_name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "price",
      headerName: "Price",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "maximum_retail_price",
      headerName: "Maxium Price",
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      flex: 0.5
    },
  ];

  const [data, setData] = useState([]);
  const fetchInitialData = useCallback(async () => {
    await fetchitemsData();
    console.log(getitems())
    setData(getitems());
  }, []);

  useEffect(() => {

    fetchInitialData();

  }, [fetchInitialData]);

  // Add an id property to each row
  const modifiedData = data.map((item, index) => ({ ...item, id: index }));


  return (
    <Box m="20px">
      <Header
        title="CONTACTS"
        subtitle="List of Contacts for Future Reference"
      />
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeader": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
         <DataGrid
         rows={modifiedData}
        // disableColumnFilter
        disableColumnSelector
        disableDensitySelector
        columns={column}
        slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
      />
      </Box>
    </Box>
  );
};

export default Contacts;