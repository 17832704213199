// src/services/dataService.js
import axios from 'axios';

let data = [];
let items = [];

const fetchData = async () => {
  try {
    const now = new Date();
    const random = now.toLocaleString();
    const response = await axios.get(`https://www.analytics.force24.in/test?timestamp=${random}`);
    data = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    data = [];
  }
};

const fetchitemsData = async () => {
  try {
    // const now = new Date();
    // const random = now.toLocaleString();
    const response = await axios.get(`https://www.analytics.force24.in/itemlist`);
    items = response.data;
  } catch (error) {
    console.error('Error fetching data:', error);
    items = [];
  }
};

const getData = () => {
  return data;
};

const getitems = () => {
  return items;
};

export { fetchData, getData, fetchitemsData, getitems};
